// general overrides for ALPS patterns

div.fluid-width-video-wrapper {
	padding-top: 54%;
}

.c-block__heading {
	figure {
		@include respond(xxlarge){
			width: calc(100vw/7 * 1);
		}
	}
}

body.hide-sabbath--all .c-article, body.hide-sabbath .c-article--no-sidebar {
	@include respond(medium){
		width: 100%;
	}
	@include respond(large){
		width: calc(100vw/7 * 5);
	}
}

.c-article__body {
	position: relative;
	a.break-all {
		word-break: break-word;
	}
	>.addthis_inline_share_toolbox {
		text-align: right;
	}
}


.c-drawer__nav-secondary {
	.gtranslate {
		padding-top: .5em;
		select {
			color: #fff;
		}
	}
}

.c-header__nav-secondary.search-inline {
	.c-secondary-nav__list {
		align-items: center;
		flex-direction: row-reverse;
		height: 2.5rem;
		justify-content: flex-end;
		padding-top: 1em;
		@include respond(small){
			justify-content: flex-start;
			padding-left: 6rem;
			padding-top: 0;
			padding-bottom: .2rem;
		}
		@include respond(medium){
			height: auto;
			flex-wrap: wrap;
			padding: 0 4.5rem 0 3rem;
		}
	}
	.c-secondary-nav__list-item__search {
		margin-left: .5rem;
		width: 15rem;
		@include respond(small){
			position: relative;
			left: auto;
			top: auto;
			width: 10rem;
		}
		@include respond(medium){
			margin: 0;
			max-width: 250px;
			right: auto;
			position: absolute;
			right: -.5rem;
			top: 2rem;
			width: 20vw;
		}
		@include respond(large){
			margin-right: .5rem;
			position: relative;
			right: auto;
			top: auto;
		}
		form {
			width: 100%;
		}
		fieldset {
			display: flex;
			align-items: center;
		}
		.search-form__input {
			border: 1px solid $gray-light;
			border-radius: 1rem;
			color: $gray-mid;
			font-size: .8em;
			line-height: 1em;
			padding: .3em .5em;
			@include respond(large){
				padding: .4em 1em;
			}
		}
		.search-form__submit {
			background-color: #fff;
			border: none;
			margin-left: -1.2rem;
			padding: 0;
			svg {
				path {
					fill: $gray-mid;
				}
			}
		}
	}
}

.c-hero-carousel {
	.c-carousel__item-text__wrap {
		align-items: flex-end;
		>div {
			margin-left: 0;
			position: relative;
			@include respond(large){
				padding-left: calc(100vw/7*1) !important;
			}
			&:after {
				@extend %header-text-bg;
			}
		}
	}
	.c-carousel__item-text {
		border-left: 2px solid #fff;
		padding-bottom: 1.25rem;
		padding-right: 20%;
		position: relative;
		z-index: 5;
	}
}

.carousel-full {
	height: 100vh;
	width: 100vw;
	.c-block__full {
		height: 100vh;
	}
}

.c-page-header__secondary-nav {
	display: none;
	@include respond(medium){
		display: block;
	}
	.c-page-header__simple--inner {
		min-height: auto;
	}
	a {
		color: #fff;
		display: inline-block;
		font-size: 1em;
		padding: .75em .5em;
		position: relative;
		text-transform: uppercase;
		@include respond(xlarge){
			font-size: 1.2em;
		}
		@include respond(xxlarge){
			padding: .75em;
		}
	}
	a:hover, a.current {
		background: rgba(0,0,0,0.15);
		text-align: center;
		&:before {
			border-top: 5px solid #fff;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			content: "";
			display: block;
		    position: absolute;
		    top: 0;
		    transform: translateX(-50%);
		    left:50%;
		    width: 0;
		    z-index: 0;
		}
	}
}

.c-page-header__wordmark-top {
	.wordmark {
		left: 0;
		position: absolute;
		max-width: 150px;
		top: 1.25rem;
		width: 30%;
		z-index: 10;
		svg {
			path {
				fill: #fff;
			}
		}
	}
	.c-page-header__simple--inner {
		padding-top: 5rem;
	}
}

.c-page-header {
	.bg {
		@extend %bg;
		@extend %fit;
	}
}

.c-page-header__long.has-background {
	background-position: center center;
	padding-top: 15rem;
	@include respond(xxxlarge){
		padding-top: 25rem;
	}
	.c-page-header__long--inner {
		&:after {
			@extend %header-text-bg;
		}
	}
	.c-page-header__content {
		color: #fff;
		padding-top: 0;
		z-index: 5;
	}
}

.c-page-header__long.magazine-header {
	padding-top: 15rem;
	@include respond(xxlarge){
		padding-top: 25vw;
	}
}

.c-page-header__long.magazine-issue {
	padding-top: 5rem;
}

.c-page-header__long.magazine-department {
	padding-top: 5rem;
	figure {
		width: 70px; height: 70px;
		position: absolute; left: -35px; top: -35px;
		svg {
			width: 100%;
			path {
				fill: #fff;
			}
		}
	}
}

.c-logo-symbol {
	max-width: 125px;
}

.logo-wide {
	svg,img {
		max-width: 15rem;
		@include respond(xxlarge){
			max-width: 20rem;
		}
	}
}

.logo-xwide {
	svg,img {
		max-width: 18rem;
		@include respond(xxlarge){
			max-width: 25rem;
		}
	}
}

.logo-xxwide {
	width: 20rem;
	@include respond(large){
		width: 22rem;
	}
	@include respond(xlarge){
		width: 24rem;
	}
	@include respond(xxlarge){
		width: 25rem;
	}
	svg,img {
		max-width: 30rem;
		@include respond(xxlarge){
			max-width: 30rem;
		}
	}
}

.c-breakout-link {
	padding-top: 20%;
	position: relative;
	.c-breakout-image__background {
		@extend %bg;
		@extend %fit;
		opacity: .5;
		z-index: 1;
	}
	.c-breakout-image__caption {
		color: #fff;
		padding-bottom: $pad-large;
		position: relative;
		z-index: 5;
		.o-button--outline {
			border-color: rgba(#fff,.5);
			color: #fff;
			svg {
				path {
					fill: #fff;
				}
			}
		}
	}
}

.c-block__row {
	position: relative;
}

.c-block__breakout {
	margin-top: -2.5rem;
	.c-secondary-nav {
		li.level1 {
			border-top: 1px solid rgba($gray-light,.2);
			padding: $pad-small 0;
			>a {
				font-weight: 600;
			}
			ul {
				padding: .5em 0 .5em 1em;
			}
		}
		li.level2 {
			font-size: .8em;
			padding: .25em 0;
		}
		a {
			opacity: .75;
		}
		a:hover {
			opacity: 1;
		}
	}
}

blockquote {
	cite {
		font-size: .7em;
		font-style: normal;
		font-weight: 200;
		margin-top: .5em;
	}
}

blockquote.with-image {
	@extend %flex-default;
	.img {
		padding-right: $pad-large;
		width: 30%;
		@include respond(medium){
			width: 20%;
		}
		@include respond(xxxlarge){
			width: 12%;
		}
	}
	.content {
		width: 70%;
		@include respond(medium){
			width: 80%;
		}
		@include respond(xxxlarge){
			width: 88%;
		}
	}
}

.noquotes {
	p:before, p:after {
		display: none !important;
	}
	p {
		text-indent: 0 !important;
	}
}

h2.js-toggle-parent {
	cursor: pointer;
	font-size: 1.5rem;
	padding: .25em 0;
	position: relative;
	&:before {
		color: $gray-mid;
		content: '\002B';
		font-size: .75em;
		position: absolute;
		left: -.2em;
		top: .6em;
	}
	&.this-is-active:before {
		content: '\2013';
	}
}

.c-block__inline {
	a:not(.o-button) {
		border-bottom: none;
	}
	.c-block__image-wrap, picture {
		height: 100%;
		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.c-media-block__image.w-icon {
	text-align: center;
	@include respond(medium){
		max-width: 200px;
	}
	svg {
		display: inline-block;
		max-width: 200px;
	}
}

.c-media-block.w-icon {
	align-items: center;
	.c-block__image {
		max-width: 60px;
		img,svg {
			width: 100%;
		}
	}
	@include respond(small){
		padding-left: .9375rem;
	}
	@include respond(large){
		padding-left: 1.25rem;
	}
}

.c-media-block__inline-cover {
	.c-block__image-wrap, picture {
		height: auto;
		img {
			height: auto;
			object-fit: contain;
		}
	}
	.c-media-block__image {
		@include respond(small){
			width: 30%;
		}
		@include respond(xxlarge){
			width: 20%;
		}
	}
	.c-media-block__content {
		@include respond(small){
			width: 70%;
		}
		@include respond(xxlarge){
			width: 80%;
		}
	}
}

.c-media-block__inset-bg {
	padding-top: 50%;
	@include respond(medium){
		padding-top: 20%;
	}
	.bg {
		@extend %bg;
		@extend %fit;
	}
	.c-block__content {
		position: relative;
	}
}

.c-block__text-expand.w-icon {
	figure.w-icon {
		max-width: 150px;
	}
	.c-block__content {
		margin-left: 0;
	}
}

.breakout-medium {
	@include respond(xxlarge){
		padding-right: calc(100vw/14 * 1);
	}
}

.breakout-small {
	@include respond(large){
		padding-right: calc(100vw/7 * 1);
	}
}

.c-block.authorbox {
	overflow: hidden;
	img {
		float: left;
		margin: 0 1em 0 .5em;
		width: 30%;
		max-width: 150px;
	}
}

.c-block.download {
	.c-block__image {
		width: 15%;
		@include respond(xxlarge){
			width: 10%;
		}
	}
	>*:not(.c-block__image) {
		margin-left: 15%;
		@include respond(xxlarge){
			margin-left: 10%;
		}
	}
}

.c-carousel, .c-accordion {
	li {
		text-indent: 0 !important;
	}
	li:before {
		display: none !important;
	}
}

.c-carousel__slides {
	picture {
		background: $gray-light;
		display: flex;
		justify-content: center;
	}
}

.form-inline {
	fieldset {
		overflow: hidden;
		width: 100%;
		>* {
			margin: 0 .5em .5em 0;
			width: auto;
			float: left;
		}
		select {
			padding-right: 1.2em;
		}
	}
}

.noimage {
	.c-media-block__image {
		display: none;
	}
	.c-block__content {
		padding-top: 0;
	}
}

.c-search-results {
	.text em {
		border-bottom: 1px solid #ccc;
		font-weight: 600;
	}
}

// theme overrides

.u-theme--denim .u-theme--background-color--lighter {
	background-color: #86D4FF;
}

