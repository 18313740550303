//content area

.text, .redactor-editor {
	.error {
		color: #ff0000;
		font-size: .85em;
		font-weight: 600;
	}
	ol {
		li {
			text-indent: 0;
		}
	}
	ul.inline {
		li {
			display: inline-block;
			padding: 0 .6em 0 .4em;
			&:first-child {
				padding-left: 0;
			}
		}
		&.divider {
			li {
				border-right: 1px solid $gray-white;
				&:last-child {
					border-right: none;
				}
			}
		}
	}
	.sidebar, .figure {
		background: $gray-white;
		overflow-x: scroll;
		>*:not(img) {
			padding: .5em $pad-small;
			@include respond(large){
				padding: .5em $pad-large;
			}
		}
		h2 {
			background: $gray-light;
			font-size: 1.3em;
			padding: 1rem;
		}
		h3 {
			font-size: 1em;
		}
		h4 {
			background: $gray-light;
			font-size: 1em;
			font-weight: 400;
			padding: 1rem;
		}
		ol,ul {
			padding-left: 3em;
			padding-top: 0;
			li {
				margin-bottom: .5em;
				margin-top: .5em;
				text-indent: 0;
			}
			ol,ul {
				font-size: .85em;
				li:before {
					margin-right: 0 !important;
				}
			}
		}
		table {
			td,p {
				font-size: .85rem;
			}
			tr,td {
				vertical-align: top;
			}
		}
		table.default {
			td {
				border: 1px solid rgba(0,0,0,.2);
			}
		}
		a {
			//word-break: break-all;
		}
		.footnotes {
			border-top: 1px solid #fff;
			padding-bottom: $pad-small;
		}
	}

	.citations, .footnotes {
		border-top: 1px solid $gray-white;
		margin-top: $pad-large;
		padding: $pad-small;
		@include respond(large){
			padding: $pad-large;
		}
		h4 {
			text-transform: uppercase;
		}
		p, ol {
			font-size: .8em;
			margin-top: 1em;
		}
		ol {
			padding-left: 1em;
			li {
				margin: .5em 0;
				text-indent: 0 !important;
				overflow-wrap: break-word;
				word-wrap: break-word;
			}
		}
	}

	//wm colors
	.nurture {
		color: #47a5ad;
	}
	.empower {
		color: #77b43d;
	}
	.outreach {
		color: #f78122;
	}
	.enditnow {
		color: #b6121b;
	}

	.search-results {
		ul.inline {
			li {
				text-indent: 0 !important;
				&:before {
					display: none !important;
				}
			}
		}
	}
}

.l-main, .redactor-editor {

	blockquote {
		font-size: 1.25em;
		font-weight: 600;
		p {
			font-size: 1em;
			font-weight: 600;
		}
		img {
			margin-bottom: $pad-small;
		}
	}

	.float-right, .img-right {
		@extend %float-right;
		@extend %float-default;
	}

	.float-left, .img-left {
		@extend %float-left;
		@extend %float-default;
	}

	.float-right-small, .img-right-small {
		@extend %float-right;
		@extend %float-small;
	}

	.float-left-small, .img-left-small {
		@extend %float-left;
		@extend %float-small;
	}

	.float-right-large, .img-right-large {
		@extend %float-right;
		@extend %float-large;
	}

	.float-left-large, .img-left-large {
		@extend %float-left;
		@extend %float-large;
	}


}