// third party customizations

.map-inline {
	background: #fff;
	padding-top: 60%;
	position: relative;
	#map {
		@extend %fit;
	}
}

#ot-sdk-btn {
	background: none !important;
	border: none !important;
	color: #fff !important;
	font-family: noto sans,helvetica,arial,sans-serif !important;
    font-size: .8125rem !important;
    line-height: 1.2;
    padding: 0 !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
}

li.gtranslate {
	>select {
		margin-top: 5px;
		width: 140px;
	}
}



// Ministry Magazine

.ads-header {
	background: $gray-light;
	a {
		display: block;
		text-align: center;
		img {
			width: 100%;
			margin: 0 auto !important;
			max-width: 800px;
		}
	}
}

.c-hero-magazine {
	@include respond(large){
		background: rgba($gray-white,.5);
		padding: $pad-large 0;
	}
	p {
		line-height: 1.4em;
	}
	.latest-feature {
		padding: 0;
		@include respond(large){
			.c-block__inset {
				height: 100%;
			}
		}
		.c-block__image {
			@extend %bg;
			padding-top: 50%;
			@include respond(large){
				height: 100%;
			}
		}
		h2 {
			font-size: 1.6em;
			margin: 0;
			@include respond(medium){
				font-size: 1.75em;
			}
		}
		p {
			margin: .2em 0 0;
		}
	}
	.latest-articles {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		@include respond(large){
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			padding-top: 0;
			padding-bottom: 0;
		}
		p {
			line-height: 1.4em;
		}
		.c-block__row {
			//flex: 1;
			flex-grow: 1;
			min-height: 100px;
			padding: $pad-small 0;
			display: flex;
			@include respond(large){
				padding: 0 0 $pad-large 0;
				&:last-child {
					padding-bottom: 0;
				}
			}
			.c-block__image {
				@extend %bg;
				width: 30%;
			}
			.c-block__content {
				width: 70%;
				padding: 0 $pad-small 0 0;
				@include respond(small){
					padding: 0 0 0 $pad-small;
				}
				@include respond(large){
					padding: $pad-small 0 $pad-small $pad-small;
				}
				@include respond(xlarge){
					padding: $pad-small;
				}
				h3 {
					font-size: 1.2em;
					@include respond(medium) {
						font-size: 1.3em;
					}
					@include respond(large) {
						font-size: 1.4em;
					}
				}
				h4,p {
					margin: 0;
					padding: .2em 0 0;
				}
				p {
					@include respond(large){
						font-size: .85em;
					}
				}
			}
		}
	}
}

header.magazine-department{
	.c-page-header__long__content {
		padding-top: 2rem;
	}
}

.grid-covers {
	display: flex;
	flex-wrap: wrap;
	a {
		border-bottom: none;
		color: #fff;
		padding: 0 $pad-small $pad-small 0;
		@include respond(xlarge){
			padding: 0 $pad-large $pad-large $pad-large;
		}
	}
}

.grid-covers-recent {
	@include respond(xlarge){
		padding-right: $pad-large;
	}
	a {
		width: 33%;
		@include respond(small){
			width: 25%;
		}
		@include respond(medium){
			width: 20%;
		}
		@include respond(large){
			width: 14.28%;
		}
	}
}

.grid-covers-archive {
	a {
		width: 25%;
	}
}

.author-box {
	display: none;
	font-size: .85em;
	overflow: hidden;
	@include respond(medium){
		display: block;
	}
	.author-box-inner {
		clear: left;
		margin-top: 1em;
		overflow: hidden;
		&:first-child {
			margin-top: 0;
		}
	}
	b {
		display: none;
	}
	figure {
		@extend %bg;
		float: left;
		margin: 0 1em 0 0;
		padding-top: 30%;
		width: 28%;
	}
	&.author-head {
		display: block;
		border-bottom: 1px solid $gray-light;
		@include respond(medium){
			display: none;
		}
		.author-box-inner {
			@extend %flex-default;
		}
		figure {
			margin: 0;
			padding: 0;
			width: 25%;
			max-width: 120px;
			min-height: 100px;
		}
		.bio {
			padding: $pad-small;
			width: 75%;
		}
	}
}

.magazine-header {
	padding-top: 25rem;
	.bg {
		@extend %bg;
		@extend %fit;
	}
}

.c-media-block-icon {
	display: flex;
	align-content: flex-start;
	align-items: flex-start;
	.c-media-block__image {
		width: 20%;
		svg {
			width: 100%;
		}
	}
	.c-media-block__content {
		width: 80%;
	}
}

.Editorial {
	svg {
		path {
			fill: #ba4559;
		}
	}
}

.Book-Review {
	svg {
		path {
			fill: #00609b;
		}
	}
}

.Practical-Pointers {
	svg {
		path {
			fill: #00a049;
		}
	}
}

.Revival-and-Reformation {
	svg {
		path {
			fill: #f05a22;
		}
	}
}

.Dateline {
	svg {
		path {
			fill: #7376b8;
		}
	}
}

.subscriptions {
	.plan {
		background: $gray-white;
		border: 1px solid #fff;
		padding-bottom: $pad-large;
	}
	.price {
		color: #fff !important;
		margin: 1.25em 0 1em;
		padding-bottom: .5em;
		text-transform: uppercase;
	}
}

.gsc-input {
	height: 36px;
	line-height: 36px;
	padding: 0 !important;
}

.gsc-search-button {
	padding: 0 0 0 5px !important;
	width: 80px !important;
	height: 49px !important;
	button {
		display: inline-block;
		margin: 0;
		width: 100%;
		height: 49px;
		line-height: 49px;
	}
	svg {
		margin-top: 12px;
		width: 25px !important;
		height: 25px !important;
	}
}

.audio-content {
	@include respond(medium){
		display: none;
	}
}

.audio-sidebar {
	display: none;
	@include respond(medium){
		display: block;
	}
}

.sibling-nav {
	display: none;
	position: absolute; left: 0; bottom: 0;
}

.user-icon-locked {
	background: $gray-light;
	padding: 3px;
	position: absolute; left: 0; top: 0;
	width: 20px; height: 27px;
	svg {
		width: 14px;
		path {
			fill: $gray-mid;
		}
	}
}

.c-secondary-nav__list-item {
	ul {
		display: none;
		border-left: 1px solid $gray-mid;
		background: $gray-white;
		position: absolute;
		left: .5em; top: 1.7em;
		li {
			color: $gray-mid;
			font-size: .85em;
			padding: .5em 1em;
		}
	}
	&:hover {
		ul {
			display: block;
		}
	}
}

// GCAS

$gcas-orange: #F89728;
$gcas-blue: #54BCEB;
$gcas-green: #7AC143;

.c-page-header__sidebar {
	@include respond(large){
		padding-top: 0 !important;
	}
	.c-page-header__long--inner {
		@include respond(large){
			align-items: flex-end;
		}
		@include respond(xxxlarge){
			display: flex;
			min-height: 500px;
		}
		&:after {
			height: 60% !important;
		}
	}
	.c-page-header__content {
		@include respond(large){
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
	.sidebar {
		background: rgba(#000,.5);
		color: #fff;
		z-index: 5;
		@include respond(large){
			width: calc(100vw/7 * 2);
		}
		@include respond(xxlarge){
			padding: 5rem 5rem $pad-large $pad-large;
		}
		@include respond(xxxlarge){
			min-height: 500px;
		}
	}
}

.text {
	.timeline-outer {
		ul.timeline-inner {
			border-left: 1px solid $gray-light;
			li {
				text-indent: 0 !important;
			}
			>li {
				.year-text {
					background: $gray-white;
					display: inline-block;
					margin: .5em 0 0 -.5em;
					padding: .3em .5em;
				}
				ul {
					padding-left: 1em;
					li {
						margin: 1em 0;
						position: relative;
						h4 {
							text-transform: uppercase;
						}
						&:before {
							border-left: 5px solid $gray-light;
							border-top: 5px solid transparent;
							border-bottom: 5px solid transparent;
							position: absolute; left: -1em; top: .3em;
							content: '' !important;
						}
					}
				}
				&:before {
					display: none !important;
				}
			}
		}
	}
	.qcf {
		.section {
			display: flex;
			>* {
				border: 1px solid $gray-white;
				padding-bottom: $pad-large;
				width: 50%;
				&:first-child {
					border-right: none;
				}
				>* {
					margin-top: 1em;
					margin-bottom: 1em;
				}
			}
			h3 {
				margin-bottom: .2em;
			}
		}
		.divider {
			text-align: center;
			overflow: visible;
			svg {
				display: inline-block;
				height: 30px;
				width: 30px;
				transform: rotate(90deg);
			}
		}
		.end {
			background: $gcas-orange;
			padding: $pad-small;
			text-align: center;
			h2 {
				color: #fff;
				text-transform: uppercase;
			}
		}
	}
}


// ABSG

.icon-links {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	>* {
		width: 20%;
		a {
			border-bottom: none;
		}
	}
	.revisions {
		width: 100%;
		h5 {
			font-size: .9em;
			padding: 1em 0 .5em;
		}
		a {
			display: block;
			width: 20%;
		}
	}
	&.horizontal {
		display: block;
		>* {
			width: 100%;
			align-content: center;
			a {
				display: flex;
				width: 100%;
			}
			h4 {
				line-height: 3em;
			}
			figure {
				margin-right: 1.5em;
				width: 40px;
			}
		}
	}
}


// ASTR

.grid-3up.allvids {
	>* {
		@include respond(small){
			width: 100%;
		}
		@include respond(xlarge){
			width: 33%;
		}
	}
	.c-media-block__description {
		font-size: .8em;
		line-height: 1.3em;
		@include respond(xlarge){
			display: none;
		}
		@include respond(xxlarge){
			display: block;
		}
	}
}

// STEWARDSHIP

.product-with-options {
	.c-media-block__image {
		@include respond(small){
			width: 20%;
		}
	}
	.c-media-block__content {
		@include respond(small){
			width: 80%;
		}
	}
	.options {
		display: flex;
		width: 100%;
		@include respond(small){
			justify-content: flex-end;
		}
		>* {
			width: 35px;
			margin-right: .5em;
		}
	}
}

.calculator {
	.calculator-field {
		border-bottom: 3px solid $gray-light;
		font-size: 1.3em;
		width: 250px;
		input {
			border: none;
			width: 220px;
		}
	}
}

#langselect {
	ul {
		display: inline-block;
		height: 1.6em;
		overflow: hidden;
		padding-left: .6em;
		position: relative;
		&:before {
			border-left: 5px solid $gray-light;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			content: '';
			display: block;
			position: absolute; left: 0; top: .45em;
		}
		&:hover {
			height: auto;
		}
		li {
			text-indent: 0 !important;
			a {
				border-bottom: none;
				line-height: 1.6em;
			}
			&:before {
				display: none;
			}
		}
	}
}

div.author {
	border-top: 1px solid $gray-light;
	clear: left;
	overflow: hidden;
	.author-row {
		padding-top: 1em;
	}
	.name {
		font-weight: 600;
	}
}

#manager-util {
	background: $gray-light;
	color: $gray-dark;
	font-weight: 600;
	padding: 5px 5vw;
	text-transform: uppercase;
}

.overlay-mgr {
	background: rgba(#000,.95);
	height: 100vh;
	padding: 2.5vh 2.5vw;
	position: fixed;
	width: 100vw;
	z-index: 1000;
	iframe {
		height: 95vh;
		width: 95vw;
	}
	.mgr-close {
		color: #ff9500;
		height: 2.5vh;
		line-height: 2.5vh;
		font-size: 2vh;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: 2.5vw;
	}
}

// SSPM

.inverse {
	.c-block__text.w-icon, .c-block__text.no-icon {
		>* {
			margin-left: 0;
		}
	}
}
