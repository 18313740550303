$xsmall: "all and (min-width: 350px)";
$small: "all and (min-width: 500px)";
$medium: "all and (min-width: 700px)";
$large: "all and (min-width: 900px)";
$xlarge: "all and (min-width: 1100px)";
$xxlarge: "all and (min-width: 1300px)";
$xxxlarge: "all and (min-width: 1500px)";

@mixin respond($media) {
  @if $media == xsmall {
    @media #{$xsmall} { @content; }
  }
  @if $media == small {
    @media #{$small} { @content; }
  }
  @if $media == medium {
    @media #{$medium} { @content; }
  }
   @if $media == large {
    @media #{$large} { @content; }
  }
  @if $media == xlarge {
    @media #{$xlarge} { @content; }
  }
  @if $media == xxlarge {
    @media #{$xxlarge} { @content; }
  }
  @if $media == xxxlarge {
    @media #{$xxxlarge} { @content; }
  }
}

$pad-small: .9375rem;
$pad-large: 1.25rem;

$gray-dark: #575a5c;
$gray-mid: #717171;
$gray-light: #d8d7d5;
$gray-white: #f1f0ef;

%bg {
	background-position: center center;
	background-size: cover;
}

%flex-default {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-start;
}

%float-right {
	@include respond(small){
		float: right;
		margin: 1em 0 1em 1.5em;
	}
}

%float-left {
	@include respond(small){
		float: left;
		margin: 1em 1.5em 1em 0;
	}
}

%float-small {
	width: 20%;
}

%float-default {
	width: 100%;
	@include respond(small){
		width: 40%;
	}
}

%float-large {
	@include respond(small){
		float: none;
		margin: 1em 0;
	}
	@include respond(medium){
		width: 50%;
		float: right;
		margin: 1em 0 1em 1.5em;
	}
	@include respond(xxlarge){
		width: 60%;
	}
}

%fit {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

%header-text-bg {
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.8));
	content: '';
	display: block;
	height: 150%;
	left: 0;
	position: absolute;
	bottom: 0%;
	width: 100%;
	z-index: 1;
}

.grid-2up, .grid-3up, .grid-4up {
	width: 100%;
	>* {
		margin-bottom: $pad-small;
	}
	a:not(.o-button) {
		border-bottom: none !important;
	}
	@include respond(small) {
		display: flex;
		flex-wrap: wrap;
		padding-top: $pad-small;
		>* {
			padding-right: $pad-small;
			width: 50%;
			@include respond(large){
				margin-bottom: $pad-large;
				padding-right: $pad-large;
			}
		}
	}
	@include respond(large){
		padding-top: $pad-large;
	}
	&.u-spacing--double {
		>* {
			margin-top: calc(2.5rem/2 * 1.5);
			@include respond(large){
				margin-top: 2.5rem;
			}
		}
	}
}

.grid-3up {
	@include respond(medium){
		>* {
			width: 33.3%;
		}
	}
}

.grid-3up-all {
	@extend %flex-default;
	>* {
		padding-right: $pad-small;
		width: 33%;
		@include respond(large){
			padding-right: $pad-large;
		}
	}
}

.grid-3up-at-large {
	width: 100%;
	>* {
		margin-bottom: $pad-small;
	}
	a:not(.o-button) {
		border-bottom: none !important;
	}
	@include respond(large) {
		display: flex;
		flex-wrap: wrap;
		padding-top: $pad-large;
		>* {
			padding-right: $pad-small;
			width: 50%;
			@include respond(large){
				margin-bottom: $pad-large;
				padding-right: $pad-large;
			}
		}
	}
	@include respond(xxlarge){
		>* {
			width: 33.3%;
		}
	}
}

.grid-3up-at-xxlarge {
	@include respond(xxlarge){
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: flex-start;
		>* {
			width: 33%;
		}
	}
}

.grid-4up {
	>* {
		@include respond(xxxlarge){
			width: 25%;
		}
	}
}

sup {
	font-size: .5em;
	line-height: 1em;
}
